<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getCashierReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol sm="12" lg="12">
              <div class="row">
                <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
                <h2 id="cashierTitle" class="mb-0 font-weight-normal">
                  {{ $t('report.txtbyCashier') }}
                </h2>
                <HelpButton :isHeader="false" class="ml-2"></HelpButton>
                </div>
                <div class="col-md-2 col-sm-3 text-right text-success">
                  <CButton
                    id="cashierExportButton"
                    v-if="isExport"
                    v-on:click="exportCashierReport()"
                    block
                    color="info"
                    style="display: flex; align-items: center; justify-content: center;"
                  >
                  <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
                  </CButton>
                </div>
              </div>
              <show-date></show-date>
              <hr />
              <div style="overflow-x:auto;">
                <DataTable
                  id="cashierDatatable"
                  :items="items"
                  :fields="fields"
                  :loading="loading"
                  hover
                  border
                  responsive
                  style="white-space:nowrap;"
                >
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
                </DataTable>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/services/service'
import report from '@/services/report'
import moment from 'moment'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: { DataTable, HelpButton },
  data() {
    return {
      data: [],
      cashiers: [],
      loadingButton: true,
      loading: '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'username', label: this.$i18n.t('employees'),_classes: 'text-dark font-weight-normal' },
        {
          key: 'grandTotal',
          label: this.$i18n.t('salesTotal'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'cash',
          label: this.$i18n.t('paymentType0'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'promptpay',
          label: this.$i18n.t('paymentType4'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'credit',
          label: this.$i18n.t('paymentType1'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'delivery',
          label: this.$i18n.t('paymentType7'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'alipay',
          label: this.$i18n.t('paymentType5'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'quickpay',
          label: this.$i18n.t('paymentType3'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'kbank',
          label: this.$i18n.t('paymentType12'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'edc',
          label: this.$i18n.t('paymentType14'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'coupon',
          label: this.$i18n.t('paymentType2'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'linepay',
          label: this.$i18n.t('paymentType9'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'deposit',
          label: this.$i18n.t('paymentType6'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'custompay',
          label: this.$i18n.t('paymentType10'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'etc',
          label: this.$i18n.t('otherPayment'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'bill',
          label: this.$i18n.t('totalBill'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'billActive',
          label: this.$i18n.t('billActive'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'billVoice',
          label: this.$i18n.t('billVoice'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'billDeposit',
          label: this.$i18n.t('billDeposit'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'billDiscount',
          label: this.$i18n.t('billDiscount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'billDiscountAmount',
          label: this.$i18n.t('billDiscountAmount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'discountItem',
          label: this.$i18n.t('discountItem'),
          _classes: 'text-right text-dark font-weight-normal',
        },
      ]
    },
    items() {
      const data = this.cashiers || []
      let detail = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        const username = item.username || '-'
        const grandTotal = util.convertCurrency(item.grandTotal || 0)
        const cash = util.convertCurrency(item.cash || 0)
        const promptpay = util.convertCurrency(item.promptpay || 0)
        const credit = util.convertCurrency(item.credit || 0)
        const delivery = util.convertCurrency(item.delivery || 0)
        const alipay = util.convertCurrency(item.alipay || 0)
        const coupon = util.convertCurrency(item.coupon || 0)
        const quickpay = util.convertCurrency(item.quickpay || 0)
        const kbank = util.convertCurrency(item.kbank || 0)
        const edc = util.convertCurrency(item.edc || 0)
        const linepay = util.convertCurrency(item.linePay || 0)
        const deposit = util.convertCurrency(item.deposit || 0)
        const custompay = util.convertCurrency(item.customPay || 0)
        const etc = util.convertCurrency(item.etc || 0)
        const allBillCount = (item.bill || 0)
        const activeBill = (item.billActive || 0)
        const voidBill = (item.billVoice || 0)
        const depositBill = (item.billDeposit || 0)
        const billDiscount = (item.billDiscount || 0)
        const billDiscountAmount = util.convertCurrency(item.billDiscountAmount || 0)
        const discountItem = util.convertCurrency(item.discountItem || 0)

        detail.push({
          username: username,
          grandTotal: grandTotal,
          cash: cash,
          promptpay: promptpay,
          credit: credit,
          delivery: delivery,
          alipay: alipay,
          coupon: coupon,
          quickpay: quickpay,
          kbank: kbank,
          edc: edc,
          linepay: linepay,
          deposit: deposit,
          custompay: custompay,
          etc: etc,
          bill: allBillCount,
          billActive: activeBill,
          billVoice: voidBill,
          billDeposit: depositBill,
          billDiscountAmount: billDiscountAmount,
          billDiscount: billDiscount,
          discountItem: discountItem,
        })
      }
      return detail
    },
  },
  created() {
    this.getCashierReport()
  },
  methods: {
    ...util,
    getCashierReport() {
      this.loadingButton = false
      this.loading = true
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: this.shopObjectId,
        startAt: startAt,
        endAt: endAt,
        limit: 50,
      }
      const headers = { shopObjectId: shopObjectId }

      axios({
        url: '/receipt/v1.0/getcashierreport/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data.summary
          this.cashiers = res.data.data.cashiers
          this.loadingButton = true
          this.loading = false
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportCashierReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const headers = { shopObjectId: shopObjectId }

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }

      report({
        url: '/receipt/v1.0/getcashierreport/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานยอดขายแยกตามพนักงาน.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
